/*=========================================================================================
  File Name: moduleUserNotification.js
  Description: UserNotification Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleUserNotificationState.js'
import mutations from './moduleUserNotificationMutations.js'
import actions from './moduleUserNotificationActions.js'
import getters from './moduleUserNotificationGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
