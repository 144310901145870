<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full p-0">
      <!-- <vs-navbar
        class="navbar-custom navbar-skelton"
        style="background-image: url('http://173.249.43.159:89/navbarheader.png')"
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
      > -->
      <vs-navbar
        class="navbar-custom navbar-skelton"
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
      >
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer mr-1"
          icon="MenuIcon"
          @click.stop="showSidebar"
        ></feather-icon>
        <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->

        <!-- <router-link
          tag="div"
          to="/"
          class="vx-logo cursor-pointer mx-auto flex items-center"
        > -->
        <div
          v-if="windowWidth >= 992"
          class="absolute left-0 top-0 flex cursor-pointer mx-auto mt-4 sm:ml-6 md:ml-6 lg:ml-6 xl:ml-6 ml-20"
        >
          <transition :name="routerTransition">
            <div
              v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
              class="router-header flex flex-wrap items-center mb-6"
            >
              <div
                class="content-area__heading"
                :class="{
                  'pr-4 border-0 md:border-r border-solid border-grey-light':
                    $route.meta.breadcrumb
                }"
              >
                <h2 class="mb-1">{{ $t(routeTitle) }}</h2>
              </div>

              <!-- BREADCRUMB -->
              <vx-breadcrumb
                class="ml-4 md:block hidden"
                v-if="$route.meta.breadcrumb"
                :route="$route"
                :isRTL="$vs.rtl"
              />
               <h2 style="margin-left:10px"> {{$store.state.title}} </h2>
            </div>
          </transition>
        </div>
        <!-- <logo class="w-10 mr-4 fill-current ٍٍtext-primary" />
        <span class="vx-logo-text text-primary"> Admin Portal</span> -->

        <!-- <logo class="w-10 mr-4 fill-current text-primary" /> -->
        <!-- <span class="vx-logo-text text-primary">{{
            $t("ProjectTitle")
          }}</span> -->
        <router-link
          tag="div"
          to="/"
          class="vx-logo cursor-pointer mx-auto flex items-center"
        >
        </router-link>

        <div class="text-right leading-tight hidden sm:block ml-3 mr-3 ">
          <p class="font-semibold" style="color:#3268a8">{{ user_Email }}</p>
          
        </div>
        <i18n style="padding-right: 20px; padding-left: 20px;" v-if="false" />

        <profile-drop-down />

           <!-- NOTIFICATIONS -->
          <vs-dropdown
            vs-custom-content
            vs-trigger-click
            class="cursor-pointer ml-5"
          >
            <feather-icon
              icon="BellIcon"
              class="cursor-pointer mt-1 sm:mr-6 mr-2"
              :badge=" notifications.filter((b)=>b.IsRead==false).length"
            ></feather-icon>
            <vs-dropdown-menu
              class="notification-dropdown dropdown-custom vx-navbar-dropdown"
            >
              <div
                class="notification-top text-center p-5 bg-primary text-white"
              >
                <h3 class="text-white">{{ notifications.filter((b)=>b.IsRead==false).length }} {{$t('New')}}</h3>
                <p class="opacity-75">App Notifications</p>
              </div>

              <VuePerfectScrollbar
                ref="mainSidebarPs"
                class="scroll-area--nofications-dropdown p-0 mb-2"
                :settings="settings"
              >
                <ul class="bordered-items">
                  <li
                    v-for="ntf in notifications"
                    :key="ntf.index" :style="[ntf.IsRead == false? {'backgroundColor':'#e8dfdf'}:'']" @click="openNotification(ntf)"
                    class="flex justify-between px-4 py-4 notification cursor-pointer"
                  >
                    <div class="flex items-start">
                      <feather-icon
                        :icon="ntf.icon"
                        :svgClasses="[
                          `text-${ntf.category}`,
                          'stroke-current mr-1 h-6 w-6'
                        ]"
                      ></feather-icon>
                      <div class="mx-2">
                        <span
                          class="font-medium block notification-title"
                          :class="[`text-${ntf.category}`]"
                          >{{ ntf.Title }}</span
                        >
                        <small>{{ ntf.Message }}</small>
                      </div>
                    </div>
                    <small class="mt-1 whitespace-no-wrap">{{
                      elapsedTime(new Date(ntf.CreatedDate))
                    }}</small>
                  </li>
                </ul>
              </VuePerfectScrollbar>
              <!-- <div
                class="checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer"
              >
                <span>View All Notifications</span>
              </div> -->
            </vs-dropdown-menu>
          </vs-dropdown>

      </vs-navbar>
    </div>
  </div>
</template>

<script>
import I18n from "./components/I18n.vue";
import ProfileDropDown from "./components/ProfileDropDown.vue";
import themeConfig from "@/../themeConfig.js";
import moduleUserNotification from "@/store/userNotification/moduleUserNotification.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  name: "the-navbar-horizontal",
  props: {},
  data() {
    return {
       settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },
      routeTitle: this.$route.meta.pageTitle,
      routerTransition: themeConfig.routerTransition || "none"
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    }
  },
  components: {
    // Logo,
    // Bookmarks,
    I18n,
    // SearchBar,
    // CartDropDown,
    // NotificationDropDown,
    ProfileDropDown,
    VuePerfectScrollbar
  },
  computed: {
     notifications()
    {
      return this.$store.state.notifications
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    user_Email() {
      return this.activeUserInfo.Email;
    },
    activeUserImg() {
      return this.$store.state.AppActiveUser.photoURL;
    },
    navbarColor() {
      let color = "#fff";
      // if (this.navbarType === "sticky") {
      //   color = "#f7f7f7";
      // } else if (this.navbarType === "static") {
      //   if (this.scrollY < 50) {
      //     color = "#f7f7f7";
      //   }
      // }

      // if (this.isThemedark === "dark") {
      //   if (color === "#fff") {
      //     color = "#10163a";
      //   } else {
      //     color = "#262c49";
      //   }
      // }

      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      return this.navbarType === "static"
        ? { transition: "all .25s ease-in-out" }
        : {};
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg shadow-none";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  created()
  {
     if (!moduleUserNotification.isRegistered) {
      this.$store.registerModule("notificationList", moduleUserNotification);
      moduleUserNotification.isRegistered = true;
    this.$store.dispatch("fetchNotifications");
    }
  },
  methods: {
      elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? " Years " : " Year ") + "ago";
      } else if (days > 0) {
        return days + (days > 1 ? " Days " : " Day ") + "ago";
      } else if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "ago";
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "ago";
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? " sec ago" : "just now");
      }

      return "Just Now";
    },
     checkNotificationAsRead(notificationId)
    {
      this.$store.dispatch("notificationList/SetNotificationAsRead",notificationId).then(()=>{
             this.$store.dispatch("fetchNotifications");
      });
      
    },
     openNotification(notification)
    {
      this.checkNotificationAsRead(notification.ID);
      notification.IsRead=true;
      this.$router.push(notification.URL);
    },
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    }
  }
};
</script>
