<template>

  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
    <span class="cursor-pointer flex items-center i18n-locale">
      <img class="h-4 w-5" :src="i18n_locale_img" :alt="$i18n.locale" />
      <span class=" sm:block ml-2">{{ getCurrentLocaleData.lang }}</span>
    </span>
    <vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
      <vs-dropdown-item @click="updateLocale('en')">
        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/en.png" alt="en" />
        &nbsp;English
      </vs-dropdown-item>
      <!-- <vs-dropdown-item @click="updateLocale('fr')">
        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/fr.png" alt="fr" />
        &nbsp;French
      </vs-dropdown-item> -->
      <vs-dropdown-item @click="updateLocale('ar')">
        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/ar.png" alt="ar" />
        &nbsp;Arabic
      </vs-dropdown-item>
      <!-- <vs-dropdown-item @click="updateLocale('pt')">
        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/pt.png" alt="pt" />
        &nbsp;Portuguese
      </vs-dropdown-item> -->
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
/* eslint-disable */
export default {
  computed: {
    i18n_locale_img() {
      return require(`@/assets/images/flags/${this.$i18n.locale}.png`);
    },
    rtl: {
      get() {
        return this.$vs.rtl;
      },
      set(val) {
        this.$vs.rtl = val;
      }
    },
    getCurrentLocaleData() {
      const locale = this.$i18n.locale;
      if (locale === "en") {
        return { flag: "us", lang: "English" };
      } else if (locale === "fr") {
        return { flag: "fr", lang: "French" };
      } else if (locale === "ar") {
        return { flag: "ar", lang: "Arabic" };
      }
    }
  },
  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("SaveLang", locale);
      if (locale === "ar") {
        this.$vs.rtl = true;
        this.ChangeUserLanguage(2);
      } else if (locale === "en") {
        this.$vs.rtl = false;
        this.ChangeUserLanguage(1);
      } else {
        this.$vs.rtl = false;
        this.ChangeUserLanguage(3);
      }
    },

    ChangeUserLanguage(languageID) {
      this.$store
        .dispatch("auth/ChangeUserLanguage", languageID)
        .then(() => {
          if (languageID == 1) {
            this.SwitchToLTR();
          } else if (languageID == 2) {
            this.SwitchToRTL();
          } else {
            this.SwitchToLTR();
          }
        })
        .catch(Error => {
          if (Error.response.status == 400) {
            this.$vs.notify({
              title: this.$t("Error"),
              text: Error.response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        });
    },
    SwitchToRTL() {
      location.reload("/");
      this.rtl = true;
    },
    SwitchToLTR() {
      location.reload("/");
      this.rtl = false;
    }
  }
};
</script>
